// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-pages-index-js": () => import("./../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-posts-blog-js": () => import("./../src/templates/posts/blog.js" /* webpackChunkName: "component---src-templates-posts-blog-js" */),
  "component---src-templates-posts-index-js": () => import("./../src/templates/posts/index.js" /* webpackChunkName: "component---src-templates-posts-index-js" */)
}

